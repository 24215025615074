
<sigecom-input-container
  [label]="label"
  [isRequired]="isRequired"
  [isDisabled]="isDisabled"
  [errorMessage]="dropdownClicked ? errorMessage : ''"
>

  <!-- <div class="multiselect" [id]="multselectIdentify"> -->
    <div class="relative" #multiselectField>
      <input
          (click)="toggleDropdown($event)"
          [value]="hasSelectedIds() ? displaySelectedValues() : 'Selecione...'"
          readonly
          sigecom-input
          class="select-none cursor-pointer"
      />
      <div
        *ngIf="dropdownStates"
        class="absolute top-12 rounded-md bg-white shadow-3xl max-h-60 overflow-auto z-40 p-4 cursor-pointer"
      >
        <div class="w-full flex items-center gap-2 mb-4">
            <input
              type="checkbox"
              (change)="selectAllOptions($event)"
              [checked]="areAllOptionsSelected()"
            >
            <div class="w-full relative">
                <input
                  type="text" placeholder="Buscar..." [(ngModel)]="searchText"
                  class="w-full h-9 border border-[#dadada] rounded-md relative pl-2 outline-none focus:ring-2 focus:ring-blue-500"
                />
                <fa-icon [icon]="'magnifying-glass'" class="absolute right-2 text-sigecom-dark-gray top-2"></fa-icon>
            </div>
        </div>

        <label class="flex leading-none items-center gap-2 mb-2 text-base cursor-pointer text-sigecom-dark-gray" *ngFor="let op of listOptions(); let i = index">
            <input
              name="op-check-list-{{i}}"
              type="checkbox"
              [value]="op[identifyProperty]"
              [checked]="isSelected(op)"
              (change)="onCheckboxChange($event)"
            >
            <span class="flex-1 max-w-[20rem] break-words">{{ op[displayProperty] }} </span>
        </label>
      </div>
  </div>

</sigecom-input-container>
