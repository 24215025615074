import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators} from "@angular/forms";

@Directive({
  selector: '[birthdate]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: BirthdateValidatorDirective,
    multi: true,
  }]
})
export class BirthdateValidatorDirective implements Validator{
  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value === null || control.value === undefined || control.value instanceof Date) {
      return { 'birthdate': true };
    }

    const dateSplitted: string[] = (control.value as string).split("-");
    const dateToTest = new Date(Number.parseInt(dateSplitted[0]), Number.parseInt(dateSplitted[1]) - 1, Number.parseInt(dateSplitted[2]));
    const dateNow = new Date();

    const isAfterCurrentDate = dateToTest >= dateNow;
    const fullYear = dateNow.getFullYear() - 100;
    const is100Years = dateToTest.getFullYear() <= fullYear;

    if (isAfterCurrentDate || is100Years) return { 'birthdate': true };
    return null;
  }

}
