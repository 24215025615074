import { HttpParams } from "@angular/common/http";
import { IPage } from "@models/pagination/page";

export function url(...args: any[]) {
  return args.join('/');
}

export function toParams(...args: any[]): HttpParams {
  let params = new HttpParams();

  args.forEach(obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined && obj[key] !== null) {
        params = params.append(key, obj[key]);
      }
    });
  });

  return params;
}

export function filterEnumForKeyValuePipe<T extends { [key: string]: string }>(enumObj: T, includedValues: T[keyof T][]): { [key in keyof T]?: T[key] } {
  return Object.entries(enumObj)
    .filter(([key, value]) => includedValues.includes(value as T[keyof T]))
    .reduce((acc, [key, value]) => {
      acc[key as keyof T] = value as T[keyof T];
      return acc;
    }, {} as { [key in keyof T]?: T[key] });
}

export function calculateDisplayTime(message: string): number {
  const baseTime = 3000;
  const timePerChar = 70;
  const maxTime = 30000;

  const displayTime = baseTime + (message.length * timePerChar);
  return Math.min(displayTime, maxTime);
}


