import {AttachmentPostForm} from "../models/form/attachment-post-form";
import {AttachmentExtensionEnum} from "../models/enums/attachment-extension-enum";
import {DocumentPostForm} from "../models/form/document-post-form";
import {DocumentPutForm} from "../models/form/put/document-put-form";
// import {AttachmentPutForm} from "../models/form/put/attachment-put-form";

export class Utils {
  public static removeElementFromArray(array: any[], item: any): void {
    let index = array.indexOf(item);
    if (index >= 0) array.splice(index, 1);
  }

  public static formatterNumber(value: number): string {
    return new Intl.NumberFormat('pt-BR', {maximumFractionDigits: 0}).format(value);
  }

  public static formatterCurrency(value: number): string {
    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', maximumFractionDigits: 2}).format(value);
  }

  public static setAttachmentoToFormField(event: Event, attachment: AttachmentPostForm) {
    const target = event.target as HTMLInputElement;
    const file: File = target.files?.item(0) as File;
    const reader = new FileReader();
    reader.readAsDataURL(file!);
    reader.onload = (e) => {
      const attachmentExtensionEnumKey: string = file.type.split("/")[1].toUpperCase();
      attachment.aneBtAnexo = reader.result as string;
      attachment.aneTxExtensaoAnexo = AttachmentExtensionEnum[attachmentExtensionEnumKey as keyof typeof AttachmentExtensionEnum]
    };
  }

  public static removeDocuments(documents: DocumentPostForm[] | DocumentPutForm[], onlyAllowed: string[], onlyAllowedInternational: string[], isInternational: boolean) {
    const toRemoveOnDocuments = [];
    for (let i = 0; i < documents.length; i = i + 1) {
      const ehPutForm = (documents[i] as DocumentPutForm).docObjForm;
      const haveData = ehPutForm ? (documents[i] as DocumentPutForm).docObjForm?.docTxDocumento : (documents[i] as DocumentPostForm).docTxDocumento;
      const stringName = ehPutForm ? (documents[i] as DocumentPutForm).docObjForm?.docTxTipo?.toString() : (documents[i] as DocumentPostForm).docTxTipo?.toString();
      let match = false;
      if (isInternational) match = onlyAllowedInternational.some(toTest => toTest === stringName);
      else match = onlyAllowed.some(toTest => toTest === stringName);
      if (!match || !haveData) {
        toRemoveOnDocuments.push(documents[i]);
      }
    }
    toRemoveOnDocuments.forEach(removeIt => {
      Utils.removeElementFromArray(documents, removeIt);
    });
  }

  // public static removeAttachments(attachments: AttachmentPostForm[] | AttachmentPutForm[], onlyAllowed: string[], onlyAllowedInternational: string[], isInternational: boolean) {
  //   const toRemoveOnAttachments = [];
  //   for (let i = 0; i < attachments.length; i = i + 1) {
  //     const ehPutForm = (attachments[i] as AttachmentPutForm).aneObjForm;
  //     const stringName = ehPutForm ? (attachments[i] as AttachmentPutForm).aneObjForm!.aneObjTipoAnexo!.toString() : (attachments[i] as AttachmentPostForm).aneObjTipoAnexo!.toString();
  //     const haveAttachmentData = ehPutForm ? (attachments[i] as AttachmentPutForm).aneObjForm!.aneBtAnexo : (attachments[i] as AttachmentPostForm).aneBtAnexo;
  //     let match = false;
  //     if (isInternational) match = onlyAllowedInternational.some(toTest => toTest === stringName);
  //     else match = onlyAllowed.some(toTest => toTest === stringName);
  //     if (!match || !haveAttachmentData) {
  //       toRemoveOnAttachments.push(attachments[i]);
  //     }
  //   }
  //   toRemoveOnAttachments.forEach(removeIt => {
  //     Utils.removeElementFromArray(attachments, removeIt);
  //   });
  // }

  public static isDefinedAndNotNull(value: unknown): value is NonNullable<typeof value> {
    return value !== undefined && value !== null;
  }

  public static deepCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
  }
}
