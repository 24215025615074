import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'sigecom-downloads',
  templateUrl: './downloads.component.html',
  styles: [
  ]
})
export class DownloadsComponent {
  qrCodeAppUrl: string = environment.downloadQrCodeUrl;
}
