import {AfterViewInit, booleanAttribute, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MediaDisplayerService} from "../../services/media-displayer.service";
import {SnackbarService} from "../../services/snackbar.service";
import {AttachmentExtensionEnum} from "../../../models/enums/attachment-extension-enum";
import { AttachmentUtils } from 'src/app/core/utilities/attachment-utils';

@Component({
  selector: 'sigecom-file-upload',
  template: `
    <sigecom-input-container [label]="label" [isRequired]="isRequired" [isDisabled]="isDisabled"
                             [errorMessage]="errorMessage" [enableRequiredBorder]="enableRequiredBorder" >
      <div class="flex justify-between items-center">

        <label #contentWrapper class="p-2 w-full flex items-center gap-2 text-sigecom-dark-gray cursor-pointer">
          <fa-icon [icon]="'file-arrow-up'"></fa-icon>

          <input
            class="w-full"
            sigecom-input type="file" [accept]="accepts"
            (change)="getAttachment($event)"
            [required]="isRequired"
          />

          {{ lastBase64Message }}

          <ng-content></ng-content>
        </label>

       <fa-icon [icon]="'eye'" class="text-sigecom-dark-gray" (click)="showImage()"></fa-icon>
      </div>
    </sigecom-input-container>
  `,
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements AfterViewInit {
  @ViewChild('contentWrapper') content?: ElementRef;

  @Output() base64: EventEmitter<string> = new EventEmitter<string>();
  @Output() format: EventEmitter<AttachmentExtensionEnum> = new EventEmitter<AttachmentExtensionEnum>();

  @Input({ transform: booleanAttribute }) enableRequiredBorder: boolean = false;
  @Input() lastBase64?: string;
  @Input() label: string = "[ DEFAULT LABEL ]";

  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() errorMessage?: string;

  @Input() accepts?: string = "image/png, image/jpeg, application/pdf";

  public readonly defaultMessage: string = "Selecione o arquivo...";
  public readonly acceptedTypes: string[] = ["IMAGE/PNG", "IMAGE/JPEG", "IMAGE/JPG", "APPLICATION/PDF"];

  constructor(private mediaDisplayerService: MediaDisplayerService, private snackbarService: SnackbarService) {
  }

  ngAfterViewInit(): void {
    const inputs: HTMLInputElement[] = this.content?.nativeElement.getElementsByTagName("input");
    for (let i = 0 ; i < inputs.length ; i++) {
      if (i != 0) {
        inputs[i].type = "hidden";
      }
    }
  }

  public showImage() {
    if (this.lastBase64) {
      this.mediaDisplayerService.sendContent(this.lastBase64);
    }
  }

  public getAttachment($event: Event) {
    const target = $event.target as HTMLInputElement;
    const file: File = target.files?.item(0) as File;
    if (!this.acceptedTypes.some(acceptedType => acceptedType === file.type.toUpperCase())) {
      this.snackbarService.showWarn("Este formato não é permitido.");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file!);
    reader.onload = (e) => {
      this.lastBase64 = reader.result as string;
      this.base64.emit(this.lastBase64);
      const attachmentExtensionEnumKey: string = file.type.split("/")[1].toUpperCase();
      const format = AttachmentExtensionEnum[attachmentExtensionEnumKey as keyof typeof AttachmentExtensionEnum];
      this.format.emit(format);
    };
  }

  get lastBase64Message(): string {
    if (this.lastBase64) {

      if (!AttachmentUtils.isBase64(this.lastBase64!)) {
        return "Clique no ícone a direita para visualizar o arquivo";
      }

      const splitValue = this.lastBase64.split(":")[1];
      return splitValue ? splitValue.split(";")[0] : this.defaultMessage;
    }
    return this.defaultMessage;
  }
}
