import { Component } from '@angular/core';

@Component({
  selector: 'sigecom-marquee',
  template: `
    <div class="fixed h-fit  -top-1 -left-14 w-80 py-0.5 px-5 bg-red-500 text-white text-center rotate-[-40deg]">
      <div class="relative flex overflow-x-hidden">
        <div class="animate-marquee whitespace-nowrap">
          <ng-container *ngFor="let item of itens">
            <span class="mx-2 font-semibold">{{ item }}</span>
          </ng-container>
        </div>

        <div class="absolute top-0 animate-marquee2 whitespace-nowrap">
          <ng-container *ngFor="let item of itens">
            <span class="mx-2 font-semibold">{{ item }}</span>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./marquee.component.scss']
})
export class MarqueeComponent {
 itens: Array<string> = new Array(5).fill('AMBIENTE DE TESTES');
}
