import { Component, ElementRef, EventEmitter, HostListener, Input, NgIterable, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'sigecom-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent<T> implements OnInit{

  // public multselectIdentify?: string;
  @ViewChild('multiselectField') multiselectField?: ElementRef;

  @Input() label: string = "Multiselect";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() errorMessage?: string;

  @Input() options: T[] = [];
  @Input() identifyProperty!: keyof T;
  @Input() displayProperty!: keyof T;
  @Input() propertyDisplayedInInput?: keyof T;

  @Input() public selectedOptionsIds: number[] = [];
  @Output() public selectedOptionsIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Output() public selectedOptionChange: EventEmitter<number> = new EventEmitter<number>();

  public dropdownStates: boolean = false;
  public dropdownClicked: boolean = false;

  public filteredOptionsArray: T[] = [];
  public searchText: string = '';

  constructor() {}

  ngOnInit(): void {
    // this.multselectIdentify = 'multiselect-field-' + Math.random().toString(36).substr(2, 9);
  }

  @HostListener('document:click', ['$event.target'])
  public onClickOutside(target: any) {
    if (this.multiselectField && !this.multiselectField.nativeElement.contains(target)) {
      this.dropdownStates = false;
    }
    // const dropdownElement = document.getElementById(this.multselectIdentify!);
    // if (dropdownElement && !dropdownElement.contains(target)) {
    //   this.dropdownStates = false;
    // }
  }

  public displaySelectedValues(): string {
    const maxToShow = 5;
    const selectedCount = this.selectedOptionsIds.length;

    const selectedOptions = this.options?.filter(op => this.selectedOptionsIds?.includes(op[this.identifyProperty] as any));

    const displayProp = this.propertyDisplayedInInput || this.displayProperty;

    const selectedDisplayValues = selectedOptions?.map(op => op[displayProp]);

    if (selectedCount <= maxToShow) {
      return selectedDisplayValues?.join(', ');
    } else {
      const firstFiveSelected = selectedDisplayValues?.slice(0, maxToShow).join(', ');
      const remainingCount = selectedCount - maxToShow;
      return `${firstFiveSelected} +${remainingCount}`;
    }
  }

  public onCheckboxChange(event: any): void {
    const selectedOptionId: number = Number(event.target.value);

    const index = this.selectedOptionsIds?.indexOf(selectedOptionId);

    if (index === -1) {
      this.dropdownClicked = true;
      this.selectedOptionsIds?.push(selectedOptionId);
    } else {
      this.selectedOptionsIds?.splice(index!, 1);
    }

    this.selectedOptionsIdsChange.emit(this.selectedOptionsIds);
    this.selectedOptionChange.emit(selectedOptionId);
    this.validateMultselectChange();
  }

  public toggleDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.dropdownStates = !this.dropdownStates;
  }

  public isSelected(op: T): boolean {
    if (!op || !this.selectedOptionsIds) {
      return false;
    }

    const id = op[this.identifyProperty] as any;
    return this.selectedOptionsIds.includes(id);
  }

  public hasSelectedIds(): boolean {
    return !!(this.selectedOptionsIds?.length > 0);
  }

  public selectAllOptions(event: any): void {
    const isChecked = event.target.checked;
    this.dropdownClicked = true;

    if (isChecked) {
      const optionsIds = this.options?.map(op => op[this.identifyProperty]) as number[];
      this.selectedOptionsIds = optionsIds;
    } else {
      this.selectedOptionsIds = [];
    }

    this.selectedOptionsIdsChange.emit(this.selectedOptionsIds);
    this.validateMultselectChange();
  }

  public areAllOptionsSelected(): boolean {
    return this.selectedOptionsIds?.length === this.options?.length;
  }

  public listOptions() {
    if (!this.searchText) return this.options;

    return this.options.filter(op => {
      const opProp = op[this.displayProperty] as string;
      return opProp.toLocaleLowerCase().includes(this.searchText);
    })
  }

  public validateMultselectChange() {
    this.errorMessage = (this.dropdownClicked && this.selectedOptionsIds.length === 0) ? 'Selecione uma opção' : '';
  }
}
