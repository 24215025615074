import {Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {SideNavService} from "../../services/side-nav.service";
import {Subscription} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../../../environments/environment";
import {IDecodedToken} from "../../../models/decoded-token";

@Component({
    selector: 'sigecom-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    private tokenData: IDecodedToken | null = null;
    private isShowingSideNav: boolean = true;
    private subscription?: Subscription;

    constructor(
        private router: Router,
        private sideNavService: SideNavService,
        private authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.authService.getTokenData.subscribe(data => this.tokenData = data);
        this.subscription = this.sideNavService.isHide.subscribe(value => this.isShowingSideNav = !value);
    }

    public toggleSideNav() {
        if (this.isShowingSideNav) {
            this.sideNavService.hide();
        } else {
            this.sideNavService.show();
        }
    }

    public logout() {
        this.authService.clearCredentials();
        this.router.navigate(['/', 'login'])
    }

    public profile() {
        this.router.navigate(["perfil"]);
    }

    public getUserName() {
        return this.tokenData?.usuTxNome;
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    protected readonly environment = environment;
}
