import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sigecom-offcanvas',
  template: `
    <sigecom-button title="Filtros" icon="filter" (buttonClicked)="toggleMenu()"/>

    <div *ngIf="open" (click)="toggleMenu()" class="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-[130]"></div>
    <div id="menu" [ngClass]="{'menu-open': open, 'menu-closed': !open}" class="fixed top-0 right-0 w-[25rem] max-h-screen overflow-y-auto h-screen flex bg-white duration-300 z-[130]">
      <div class="w-full flex flex-col gap-4 text-black px-5 py-4 mt-4 space-y-4 overflow-y-auto">
        <div class="flex justify-between items-center">
          <h4 class="text-2xl font-bold text-[#333333]">Filtros</h4>
          <a (click)="toggleMenu()" class="text-right text-4xl hover:text-red-400 cursor-pointer">&times;</a>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./offcanvas.component.scss']
})
export class OffcanvasComponent {
  open: boolean = false;
  @Output() clearForm: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  toggleMenu() {
    this.open = !this.open;
    this.clearForm?.emit(this.open);
  }
}
