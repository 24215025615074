import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[sigecom-input]'
})
export class InputDirective implements OnInit {
  private attributeName: string = "style";
  private css: string = `
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    outline: none;
  `;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.elementRef.nativeElement.setAttribute(this.attributeName, this.css);
    this.elementRef.nativeElement.className = "input input-file-upload";
  }
}
