<div class="h-full w-full flex flex-row" *ngIf="!externalRequest">

  <sigecom-side-nav [@slideOpenClose]="hideSideNavAnimation" *ngIf="!isLoginPage && !environment.devBeta"/>

  <div class="h-full w-full flex flex-col overflow-x-hidden overflow-y-scroll">
    <sigecom-header class="w-full sticky top-0 z-100" *ngIf="!isLoginPage" />

    <div class="bg-sigecom-container flex-1 relative">
      <router-outlet></router-outlet>
    </div>

    <sigecom-footer *ngIf="!isLoginPage"/>

    <sigecom-snackbar/>
  </div>

</div>

<sigecom-confirmation-modal />
<sigecom-media-displayer-modal/>
<router-outlet *ngIf="externalRequest"></router-outlet>
