import { HttpResponse } from "@angular/common/http";
import { IBodyExceptionDto } from "../models/exceptions/body-error-dto";
import { IResponseDto } from "../models/exceptions/response-dto";

export class AttachmentUtils {

  private constructor() { }

  public static convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }

  public static isBase64(str: string) {
    return str.startsWith("data:");
  }

  public static handleBlobError(blob: Blob): Promise<IResponseDto<IBodyExceptionDto> | null> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result as string;
        const contentJson = JSON.parse(content);
        resolve(contentJson as IResponseDto<IBodyExceptionDto>);
      };
      reader.readAsText(blob);
    });
  }

  public static handleArrayBufferError(buffer: ArrayBuffer): Promise<IResponseDto<IBodyExceptionDto> | null> {
    return new Promise((resolve) => {
      const decoder = new TextDecoder();
      const content = decoder.decode(buffer);
      const contentJson = JSON.parse(content);
      resolve(contentJson as IResponseDto<IBodyExceptionDto>);
    });
  }

  public static downloadFile(response: HttpResponse<ArrayBuffer>): void {
    const body = response.body!;
    const contentType = response.headers.get("content-type")!;
    const fileName = response.headers.get('content-disposition')?.split("=")[1].replaceAll("\"", "");

    const blob = new Blob([body], { type: contentType });

    const url = window.URL.createObjectURL(blob);

    const htmlAnchorElement = document.createElement("a");
    htmlAnchorElement.href = url;
    htmlAnchorElement.download = fileName!;
    htmlAnchorElement.click();
  }
}
