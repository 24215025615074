import {Component, EventEmitter, Input, Output, booleanAttribute} from '@angular/core';

@Component({
  selector: 'sigecom-input-search',
  template: `
    <div class="rounded-md shadow-flat-xl overflow-hidden flex">
      <input class="h-full w-full rounded-s-md bg-white outline-none border border-white
       focus:border focus:border-cbde-primary" #searchInput
             [value]="search"
             type="search"
             placeholder="{{ placeholder || 'Pesquisar...' }}"
             (input)="emptyInput(searchInput.value)"
             (keyup.enter)="onSearchChange(searchInput.value)"
      />
      <div class="bg-sigecom-gray text-white cursor-pointer flex items-center px-1.5 border border-sigecom-gray"
        (click)="onSearchChange(searchInput.value)">
        <fa-icon [icon]="'magnifying-glass'"></fa-icon>
      </div>
    </div>
  `,
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent {
  @Input() placeholder?: string;
  @Input() search: string = "";
  @Input({ transform: booleanAttribute }) lowercase: boolean = false;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();

  onSearchChange(newValue: string) {
    this.search = newValue;
    this.searchChange.emit(this.lowercase ? newValue : this.search.toUpperCase());
  }

  emptyInput(newValue: string) {
    if (newValue === "") {
      this.searchChange.emit("");
    }
  }
}
