import {Injectable} from "@angular/core";
import {FormGroup, NgForm} from "@angular/forms";
import {ResponseDto} from "../models/exceptions/response-dto";
import {FormErrorDto, IFormExceptionDto} from "../models/exceptions/form-error-dto";
import {BodyErrorDto, IBodyExceptionDto} from "../models/exceptions/body-error-dto";
import {IRuleExceptionDto, RuleErrorDto} from "../models/exceptions/rule-error-dto";
import {SnackbarService} from "../shared/services/snackbar.service";
import {messages} from "./messages";
import { IUserPendingValidationsDto } from "@models/portal/validator/dto/user-pending-validation-dto";

@Injectable({
  providedIn: 'root'
})
export class MessageErrorResolver {
  private formKeyParam: Map<string, string> = new Map<string, string>();
  private formKeyValidationArgument: Map<string, ParamValidationArgument[]> = new Map<string, ParamValidationArgument[]>();
  private formValidationMessages: Map<string, string> = new Map<string, string>();
  private formValidationMessagesBackup: Map<string, string> = new Map<string, string>();

  constructor(private snackbarService: SnackbarService) {
  }

  public clone(): MessageErrorResolver {
    return new MessageErrorResolver(this.snackbarService);
  }

  public setupParam(formKey: string, apiParam: string, args?: ParamValidationArgument[]): void {
    const keyJaExistente: string | undefined = this.formKeyParam.get(formKey);
    if (!keyJaExistente) {
      this.formKeyParam.set(formKey, apiParam);
      if (args) this.formKeyValidationArgument.set(formKey, args);
      this.formValidationMessages.set(formKey, "");
      this.formValidationMessagesBackup.set(formKey, "");
    }
  }

  public resolveMessageWithForm(responseDto: ResponseDto<any>, form: NgForm): Map<string, string> {
    if (responseDto.type === 0) return new Map<string, string>;
    this.formValidationMessages = new Map<string, string>(this.formValidationMessagesBackup);

    if (responseDto.type === 1) {
      const formExceptionDto: FormErrorDto = (responseDto.response as IFormExceptionDto);
      this.formValidationMessages = this.resolveValidations(formExceptionDto, form);
    }

    if (responseDto.type === 2) {
      const bodyExceptionDto: BodyErrorDto = (responseDto.response as IBodyExceptionDto);
      this.snackbarService.showWarn(bodyExceptionDto.message);
    }

    if (responseDto.type === 3 || responseDto.type === 4) {
      const ruleExceptionDto: RuleErrorDto = (responseDto.response as IRuleExceptionDto);
      this.snackbarService.showWarn(ruleExceptionDto.message);
    }

    return this.formValidationMessages;
  }

  public resolveMessage(responseDto: ResponseDto<any>): Map<string, string> {
    if (responseDto.type === 0) return new Map<string, string>;
    this.formValidationMessages = new Map<string, string>(this.formValidationMessagesBackup);

    if (responseDto.type === 1) {
      this.snackbarService.showWarn("Ocorreram erros de validação de formulário!");
    }

    if (responseDto.type === 2) {
      const bodyExceptionDto: BodyErrorDto = (responseDto.response as IBodyExceptionDto);
      this.snackbarService.showWarn(bodyExceptionDto.message);
    }

    if (responseDto.type === 3) {
      const ruleExceptionDto: RuleErrorDto = (responseDto.response as IRuleExceptionDto);
      this.snackbarService.showWarn(ruleExceptionDto.message);
    }

    if (responseDto.type === 5) {
      const bodyExceptionDto: IUserPendingValidationsDto = (responseDto.response);
      this.resolveObjectError(bodyExceptionDto);
    }

    return this.formValidationMessages;
  }

  resolveObjectError(bodyExceptionDto: IUserPendingValidationsDto) {
    const { uvcListCampos, uvcListContatos, uvcListAnexos, uvcListDocumentos, uvcListLogradouro } = bodyExceptionDto;

    let message = 'Para inscrever o usuário no evento os seguintes campos devem ser preenchidos no cadastro do mesmo:';

    const sections = [];

    if (uvcListCampos?.length! > 0) {
      sections.push(`Dados Básicos (${uvcListCampos?.join(', ')})`);
    }

    if (uvcListContatos?.length! > 0) {
      sections.push(`Contatos (${uvcListContatos?.join(', ')})`);
    }

    if (uvcListAnexos?.length! > 0) {
      sections.push(`Anexos (${uvcListAnexos?.join(', ')})`);
    }

    if (uvcListDocumentos?.length! > 0) {
      sections.push(`Documentos (${uvcListDocumentos?.join(', ')})`);
    }

    if (uvcListLogradouro?.length! > 0) {
      sections.push(`Logradouro (${uvcListLogradouro?.join(', ')})`);
    }

    if (sections?.length! > 0) {
      message += ' ' + sections.join(', ') + '.';
    } else {
      message += ' Nenhum campo adicional é necessário.';
    }

    this.snackbarService.showWarn(message);
  }

  public showErrorMessageForKey(form: NgForm | FormGroup, key: string): string {
    let message: string | undefined;

    if ((form.controls[key]?.invalid && form.controls[key]?.touched) || form.controls[key]?.dirty) {

      const validationTypesKeys: string[] = Object.keys(ValidationTypes);
      validationTypesKeys.forEach((validationKey: string) => {

        if (form.controls[key]?.errors?.[validationKey]) {

          if (ValidationTypes.apiError === ValidationTypes[validationKey as keyof typeof ValidationTypes]) {
            message = this.formValidationMessages.get(key);
            return;
          }

          message = messages.validation[validationKey];
          message = this.setValidationArgumentForMessage(key, validationKey, message!);
          return;

        }

      });

    }

    return message ? message : "";
  }

  private setValidationArgumentForMessage(key: string, validationKey: string, currentMessage: string): string {
    const keyArguments = this.formKeyValidationArgument.get(key);

    if (keyArguments) {

      const paramValidationArgument = keyArguments.filter(argument =>
        argument.validation === ValidationTypes[validationKey as keyof typeof ValidationTypes])[0];

      if (paramValidationArgument) {
        currentMessage = currentMessage.slice(0, -1);
        currentMessage = currentMessage?.concat(": ", paramValidationArgument.value, ".");
      }

    }

    return currentMessage;
  }

  private resolveValidations(formExceptionDto: IFormExceptionDto, form: NgForm): Map<string, string> {
    const formValidationMessages: Map<string, string> = new Map<string, string>();

    const formKeysRegistered = Array.from(this.formKeyParam.values());
    const fieldMessagesNotRegistered = formExceptionDto.errors.filter(error => !formKeysRegistered.some(keyTeste => keyTeste === error.label));
    fieldMessagesNotRegistered.forEach(fieldMessage => {
      const randomKey = Math.floor(Math.random() * 1000000000);
      this.setupParam("#".concat(randomKey.toString()), fieldMessage.label);
    });

    this.formKeyParam.forEach((value, key) => {

      const messagesOfKey: string = formExceptionDto.errors.filter(error => error.label === value)
        .map(error => error.message)
        .join("; ");

      if (messagesOfKey) {
        const messagesOfKeyTransformed: string = messagesOfKey.charAt(0).toUpperCase() + messagesOfKey.slice(1);

        if (key.includes("#")) {
          this.snackbarService.showWarn(messagesOfKeyTransformed);
        }

        else {
          form.controls[key].setErrors({"apiError": true});
          form.controls[key].markAsTouched();
          formValidationMessages.set(key, messagesOfKeyTransformed);
        }

      }
    });
    return formValidationMessages;
  }
}

export interface ParamValidationArgument {
  validation: ValidationTypes,
  value: any
}

export enum ValidationTypes {
  required,
  minlength,
  maxlength,
  apiError,
  birthdate,
  mask,
  min,
  max,
  email,
  pattern
}
