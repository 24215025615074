export const environment = {
  production: false,
  apiUrl: 'https://sigecomapi-qa.mms.inf.br/api',
  useSintegra: false,
  devBeta: false,
  versionNumber: "1.9.71",
  versionTag: "HML",
  enableMarquee: true,
  downloadQrCodeUrl: 'https://sigecom-erp-qa.mms.inf.br/resources/cbde_qr_code_reader.zip'
}
