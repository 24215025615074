import { Component, Input, booleanAttribute } from '@angular/core';

@Component({
  selector: 'sigecom-list-divider',
  template: `
    <div class="border border-mms-gray-3 mx-3 h-5"></div>
  `,
  styles: [
  ]
})
export class ListDividerComponent {
}
