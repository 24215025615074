import { Component, Input } from '@angular/core';

@Component({
  selector: 'sigecom-outlined-box',
  template: `
    <div class="border border-sigecom-outlined-gray rounded-md p-{{ padding || '8' }} m-{{ margin || '4' }}">
        <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./outlined-box.component.scss']
})
export class OutlinedBoxComponent {

  @Input() margin?: string;
  @Input() padding?: string;

}
