import {Component, EventEmitter, Input, Output} from '@angular/core';
import { IconName } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sigecom-button',
  template: `
    <button
      [disabled]="disabled" (click)="this.buttonClicked.emit(true)"
      type="{{ submitButton ? 'submit' : 'button' }}"
      class="flex items-center gap-2 px-3 py-1.5 rounded shadow-flat-xl cursor-pointer justify-center whitespace-nowrap
      transition-colors duration-300 disabled:opacity-40 disabled:pointer-events-none {{ class }}"
      [ngClass]="getBaseStyle()"
    >
      <ng-container *ngIf="!enableLoading; else showLoading">
       <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
        <span *ngIf="title">{{ title }}</span>
      </ng-container>

      <ng-template #showLoading>
        <sigecom-circle-loading [sizeInRem]="1.5" color="white" />
      </ng-template>
    </button>
  `,
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() type: ButtonType = 'default';
  @Input() outlined: boolean = false;
  @Input() icon?: IconName;
  // sm xs md lg xl
  @Input() class?: string;
  @Input() title?: string;
  @Input() disabled: boolean = false;
  @Input() submitButton: boolean = false;
  @Input() enableLoading: boolean = false;
  @Output() buttonClicked: boolean | any = new EventEmitter();

  // TODO: buscar features do tailwind para refatorar cód abaixo
  getBaseStyle() {
    switch (this.type.toLowerCase()) {
      case 'info':
        return `${
          this.outlined
          ? `bg-transparent text-sigecom-info border border-sigecom-info
          hover:bg-sigecom-info hover:text-white transition-colors duration-300`
          : `bg-sigecom-info text-white`
        }`;
      case 'success':
        return `${
          this.outlined
          ? `bg-transparent text-sigecom-success border border-sigecom-success
          hover:bg-sigecom-success hover:text-white transition-colors duration-300`
          : `bg-sigecom-success text-white`
        }`;
      case 'danger':
        return `${
          this.outlined
          ? `bg-transparent text-sigecom-danger border border-sigecom-danger
          hover:bg-sigecom-danger hover:text-white transition-colors duration-300`
          : `bg-sigecom-danger text-white`
        }`;
      case 'warn':
        return `${
          this.outlined
          ? `bg-transparent text-sigecom-warn border border-sigecom-warn
          hover:bg-sigecom-warn hover:text-white transition-colors duration-300`
          : `bg-sigecom-warn text-white`
        }`;
      default:
        return `bg-white text-black`;
    }
  }
}

export type ButtonType = 'danger' | 'success' | 'info' | 'warn' | 'default';
