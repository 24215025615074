import {Component, ElementRef, HostListener, Input} from '@angular/core';

@Component({
  selector: 'sigecom-dropdown',
  template: `
    <div class="flex items-center justify-center relative cursor-pointer">
      <ng-content select="[buttonContent]"></ng-content>
      <div class="absolute shadow-3xl -left-[{{displacementXAxis}}%] top-full bg-white flex flex-col rounded-md overflow-hidden z-40" *ngIf="isOpen">
        <ng-content select="[dropdownContent]"></ng-content>
      </div>
    </div>
  `,
})
export class DropdownComponent {
  isOpen = false;
  @Input() displacementXAxis: number = 350; 
    
  constructor(private elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any): void {
    if (this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = false;
    }
  }
}
