import { Utils } from "./utils";

export class ArrayUtils {

  constructor() {}

  public static arraysIsEquals<T>(array1: T[], array2: T[]): boolean {
    if (!Utils.isDefinedAndNotNull(array1) || !Utils.isDefinedAndNotNull(array2)) {
      return false;
    }

    if (array1.length !== array2.length) {
      return false;
    }

    for (const element of array1) {
      if (!array2.find(item => item === element)) {
        return false;
      }
    }

    return true;
  }
}
