import { Component, Input } from '@angular/core';

@Component({
  selector: 'sigecom-list-title',
  template: `
    <p class="text-lg font-bold text-[#333333]">
      {{ text }}
    </p>
  `,
  styles: [
  ]
})
export class ListTitleComponent {
  @Input() text: string = '';
}
