import {Component, OnInit} from '@angular/core';
import {MediaDisplayerService} from "../../services/media-displayer.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'sigecom-media-displayer-modal',
  template: `
    <div class="bg-sigecom-modal-dark fixed inset-0 w-full h-full z-[120] flex justify-center items-center flex-col" *ngIf="isActive">

    <div class="rounded-full w-10 h-10 absolute right-9 top-7 flex justify-center items-center text-3xl cursor-pointer text-white">
      <fa-icon icon="xmark" aria-label="close" (click)="closeModal()"></fa-icon>
    </div>


      <ng-container *ngIf="loadedResources; else loadingResources">
        <div class="max-h-[28rem] max-w-full flex items-center justify-center overflow-hidden" *ngIf="isImage">
          <img [src]="base64" alt="" class="max-w-[100%] max-h-[100%] block m-auto" />
        </div>

        <object *ngIf="isPdf" [data]="base64" type="application/pdf" class="w-[50%] h-[90%]"></object>

        <p class="text-white text-center mt-4 text-sm">
          Se a visualização falhar, ou desejar baixar o arquivo,
          <a class="font-bold text-blue-500" [href]="base64" download>clique aqui!</a>
        </p>
      </ng-container>

      <ng-template #loadingResources>
        <div class="w-full flex justify-center">
          <sigecom-circle-loading [sizeInRem]="3"/>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./media-displayer-modal.component.scss']
})
export class MediaDisplayerModalComponent implements OnInit {
  public isActive: boolean = false;
  public isPdf = false;
  public isImage = false;
  public base64: any = "";
  public loadedResources: boolean = false;

  constructor(
    public mediaDisplayerService: MediaDisplayerService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
      this.mediaDisplayerService.isActive.subscribe(activeState => this.isActive = activeState);
      this.mediaDisplayerService.getBase64.subscribe(base64 => {
        this.base64 = base64;
        this.loadedResources = true;
        this.toggleAllFalse();
        const formatParts: string[] = this.getFormatParts();
        if (formatParts[0].toUpperCase() === "IMAGE") this.isImage = true;
        if (formatParts[1].toUpperCase() === "PDF") this.isPdf = true;
        this.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(base64);
      });
  }

  closeModal() {
    this.mediaDisplayerService.close();
    this.base64 = "";
    this.loadedResources = false;
  }

  private toggleAllFalse() {
    this.isPdf = false;
    this.isImage = false;
  }

  private getFormatParts() {
    return this.base64.split(":")[1].split(";")[0].split("/")
  }
}
