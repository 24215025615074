import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {
  private modalOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public modalOpen$: Observable<boolean> = this.modalOpenSubject.asObservable();

  private modalArgsSubject: BehaviorSubject<IConfirmationModal | null> = new BehaviorSubject<IConfirmationModal | null>(null);
  public modalArgs$: Observable<IConfirmationModal | null> = this.modalArgsSubject.asObservable();

  constructor() { }

  openModal(args?: IConfirmationModal) {
    if (args) {
      this.modalArgsSubject.next(args);
    }
    this.modalOpenSubject.next(true);
  }

  closeModal() {
    this.modalOpenSubject.next(false);
    this.modalArgsSubject.next(null);
  }

  clearArgs() {
    this.modalArgsSubject.next(null);
  }
}

export enum ConfirmationModalType {
  success = 'success',
  danger = 'danger',
  warn = 'warn',
  info = 'info',
}

export interface IConfirmationModal {
  modalType: ConfirmationModalType
  confirmText: string;
  cancelText: string;
  message: string;
  resourceName?: string;
  callbackExecuteAction: () => void;
}
