<sigecom-total-records-list list-component [totalRecords]="totalRecords"/>

<table *ngIf="rows.length" class="w-full text-base text-left bg-white text-gray-500 shadow-md">
  <thead class="text-sm text-white uppercase bg-[#565758]">
    <tr>
      <th
        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        *ngFor="let column of columns; let i = index"
      >
        {{ column | titlecase }}
      </th>
      <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"></th>
    </tr>
  </thead>
  <tbody>
    <tr style="word-break: break-all" *ngFor="let row of rows; let ir = index">

      <td
        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm p-4"
        *ngFor="let column of columns; let i = index"
      >
        {{ mapDataForRespectiveColumn(column, row) }}
      </td>

      <td class="w-16">
        <!-- extract for component -->
        <sigecom-dropdown [displacementXAxis]="hasExtraButtons()">
          <div class="icon" buttonContent>
            <div>
              <fa-icon [icon]="'ellipsis-vertical'"></fa-icon>
            </div>
          </div>

          <div dropdownContent class="divide-y divide-gray-300 min-w-[9rem]">
            <button
              class="w-full p-2 flex gap-1 hover:bg-gray-200"
              (click)="triggerEditMethod(row)"
            >
              <span class="block">Editar</span>
            </button>

            <button
              id="btn-delete" class="w-full p-2 flex gap-1 bg-transparent hover:bg-red-500 text-red-500 hover:text-white transition duration-300 "
              (click)="triggerDeleteMethod(row)"
            >
              <span class="block">Excluir</span>
            </button>

            <button
              class="w-full p-2 flex items-center justify-start gap-1 hover:bg-gray-200"
              *ngIf="extraButtonLabel" id="btn-extra"
              (click)="triggerExtraMethod(row)"
            >
              <span class="block">{{ extraButtonLabel }}</span>
            </button>

            <button
              class="w-full p-2 flex items-center justify-start gap-1 hover:bg-gray-200"
              *ngIf="extraButtonLabel2" id="btn-extra2"
              (click)="triggerExtraMethod2(row)"
            >
              <p class="block">{{ extraButtonLabel2 }}</p>
            </button>

            <button
              class="w-full p-2 flex items-center justify-start gap-1 hover:bg-gray-200"
              *ngIf="extraButtonLabel3" id="btn-extra3"
              (click)="triggerExtraMethod3(row)"
            >
              <p class="block">{{ extraButtonLabel3 }}</p>
            </button>

            <button
              class="w-full p-2 flex items-center justify-start gap-1 hover:bg-gray-200"
              *ngIf="extraButtonLabel4" id="btn-extra3"
              (click)="triggerExtraMethod4(row)"
            >
              <p class="block">{{ extraButtonLabel4 }}</p>
            </button>
          </div>

        </sigecom-dropdown>
      </td>
    </tr>
  </tbody>
</table>
