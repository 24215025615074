import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'sigecom-select-search-container',
  template: `
      <div
        class="rounded-md border border-sigecom-{{ errorMessage ? 'danger' : 'outlined-gray' }} min-h-[3.75rem] transition-colors duration-300 ease-in
        text-sigecom-outlined-gray p-2"
        [ngClass]="{
          'pointer-events-none opacity-30': isDisabled
        }"
      >
        <label class="font-bold text-sm p-2 mb-1 block w-full text-sigecom-{{ errorMessage ? 'danger' : 'gray' }}">
          {{ label }} {{ isRequired ? '*' : '' }}
        </label>

        <input
          class="w-full outline-none border border-gray-300 rounded mb-2 focus:ring-1 focus:ring-cbde-primary"
          #searchInput type="text" placeholder="Pesquise..."
          (keyup)="searchEmmit.emit(searchInput.value)"
        />

        <div class="border border-gray-300 rounded" #contentWrapper>
          <ng-content></ng-content>
        </div>
      </div>
      <div *ngIf="errorMessage" class="text-xs mt-1 text-sigecom-danger">
        <div>
            {{ errorMessage }}
        </div>
      </div>
  `,
  styleUrls: ['./select-search-container.component.scss']
})
export class SelectSearchContainerComponent implements AfterViewInit {
  @ViewChild('contentWrapper') content?: ElementRef;
  @Input() label: string = "[ DEFAULT LABEL ]";

  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() errorMessage?: string;
  @Output() searchEmmit: EventEmitter<string> = new EventEmitter<string>();

  ngAfterViewInit(): void {
    const selectElement: HTMLSelectElement = this.content?.nativeElement.getElementsByTagName("select")[0];
    selectElement.size = 5;
    selectElement.classList.add("select-search");
  }
}
