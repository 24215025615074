import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetimeFormat'
})
export class DatetimeFormatPipe implements PipeTransform {

  transform(value: Date, format?: string): string {
    const date = new Date(value);
    format = format || 'dd/mm/yyyy hh:mm:ss';
    return this.formatDate(date, format);
  }

  private formatDate(date: Date, format: string): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    format = format.replace('dd', day);
    format = format.replace('mm', month);
    format = format.replace('yyyy', year);
    format = format.replace('hh', hours);
    format = format.replace('mm', minutes);
    format = format.replace('ss', seconds);

    return format;
  }
}
