import {Component, OnDestroy, OnInit} from '@angular/core';
import {state, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut} from '../../animations/crossFade';
import {LoadingService} from "../../services/loading.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'sigecom-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({transform: 'translateY(0)'})),
      transition(':enter', [useAnimation(fadeIn)]),
      transition(':leave', [useAnimation(fadeOut)]),
    ])
  ]
})
export class LoadingComponent implements OnInit, OnDestroy {
  public active = false;
  private loadingSub?: Subscription;

  constructor(private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.loadingSub = this.loadingService.isActive.subscribe(active => this.active = active);
  }

  ngOnDestroy(): void {
    this.loadingSub?.unsubscribe();
  }
}
