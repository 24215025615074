<div class="flex justify-between mb-4">

  <h2 class="text-2xl text-sigecom-gray font-semibold">{{ listName }}</h2>

  <div class="flex gap-2 items-center">

    <ng-container *ngIf="isCbdeAdmin">

          <sigecom-offcanvas>
            <h1>hello</h1>
          </sigecom-offcanvas>

      <select
        class="w-52 shadow-md rounded-md px-1 outline-none border border-white focus:border-cbde-primary"
        required name="fedNrId" (change)="onChangeFederation($event)"
      >
        <option [ngValue]="undefined" selected>Filtrar por uma federação...</option>
        <option *ngFor="let federation of federations?.content" [value]="federation.fedNrId">
            {{ federation.fedTxNome }}
        </option>
      </select>

    </ng-container>

    <ng-container *ngIf="hasEventFilter">

      <select
        class="w-52 shadow-md rounded-md px-1 outline-none border border-white focus:border-cbde-primary"
        required name="eveNrId" (change)="onChangeEvent($event)"
      >
        <option [ngValue]="undefined" selected>Filtrar por evento...</option>
        <option *ngFor="let event of events?.content" [value]="event.eveNrId">
            {{ event.eveTxNome }}
        </option>
      </select>

    </ng-container>

    <sigecom-input-search (searchChange)="search($event)" [placeholder]="placeholder"/>

    <sigecom-button *ngIf="buttonNavigateLabel" [title]="buttonNavigateLabel" icon="clipboard"  (buttonClicked)="create()"/>

  </div>
</div>
