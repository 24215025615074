import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription, filter} from "rxjs";
import {environment} from "../environments/environment";
import {AuthService} from "./core/shared/services/auth.service";
import { SideNavService } from './core/shared/services/side-nav.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideOpenClose', [
      state('open', style({ width: '28%' })),
      state('close', style({ width: '5%' })),
      transition('open <=> close', animate('400ms ease-in-out')),
    ])
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public isLoginPage: boolean = true;
  public externalRequest: boolean = true;
  public title = 'sigecom';

  public subscription?: Subscription;
  public hideSideNav: boolean = false;
  public hideSideNavAnimation: 'open' | 'close' = 'open';

  constructor(
    private authService: AuthService, 
    private router: Router,
    private sideNavService: SideNavService
  ) {}

  ngOnInit(): void {
    this.subscription = this.sideNavService.isHide.subscribe(value => {
      this.hideSideNav = value;    
      this.hideSideNavAnimation = value ? 'close' : 'open';    
    });

    this.authService.verifyTokenAndSetupData();
    console.log(environment.apiUrl)
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.isLoginPage = (event as NavigationEnd).url.includes("/login");
    });

    this.router.events.subscribe(firedEvent => {
      if (firedEvent instanceof NavigationEnd) {
        this.externalRequest = firedEvent.url.includes('/app');
      }
    })
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  protected readonly environment = environment;
}
