import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideNavComponent} from "./components/side-nav/side-nav.component";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {ConfirmationModalComponent} from "./components/confirmation-modal/confirmation-modal.component";
import {LoadingComponent} from "./components/loading/loading.component";
import {HeaderComponent} from "./components/header/header.component";
import {DropdownComponent} from "./components/dropdown/dropdown.component";
import {FooterComponent} from "./components/footer/footer.component";
import {SnackbarComponent} from './components/snackbar/snackbar.component';
import {InputContainerComponent} from "./components/input-container/input-container.component";
import {InputDirective} from "./directives/input.directive";
import {ContentContainerComponent} from './components/content-container/content-container.component';
import {ButtonComponent} from './components/button/button.component';
import {FileUploadComponent} from "./components/file-upload/file-upload.component";
import {InputSearchComponent} from './components/input-search/input-search.component';
import {HttpClientModule} from "@angular/common/http";
import {SelectSearchContainerComponent} from './components/select-search-container/select-search-container.component';
import {ListComponent} from './components/list/list.component';
import {TopListComponent} from './components/top-list/top-list.component';
import {PaginationListComponent} from './components/pagination-list/pagination-list.component';
import { PanelBoxComponent } from './components/panel-box/panel-box.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MediaDisplayerModalComponent } from './components/media-displayer-modal/media-displayer-modal.component';
import { BirthdateValidatorDirective } from './directives/birthdate-validator.directive';
import { CircleLoadingComponent } from './components/circle-loading/circle-loading.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { OutlinedBoxComponent } from './components/outlined-box/outlined-box.component';
import { ListContainerComponent } from './components/list-container/list-container.component';
import { NotFoundMessageListComponent } from './components/not-found-message-list/not-found-message-list.component';
import { TotalRecordsListComponent } from './components/total-records-list/total-records-list.component';
import { DatetimeFormatPipe } from './pipes/datetime-format.pipe';
import { MessageComponent } from './components/message/message.component';
import { ModalComponent } from './components/modal/modal.component';
import { DragDropComponent } from './components/drag-drop/drag-drop.component';
import { SwitchComponent } from './components/switch/switch.component';
import { MarqueeComponent } from './components/marquee/marquee.component';
import { ListItemComponent } from './components/list/list-item/list-item.component';
import { ListTitleComponent } from './components/list/list-title/list-title.component';
import { ListInfoComponent } from './components/list/list-info/list-info.component';
import { ListDividerComponent } from './components/list/list-divider/list-divider.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { MenuBarItemComponent } from './components/menu-bar/menu-bar-item/menu-bar-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OffcanvasComponent } from './components/offcanvas/offcanvas.component';
import { BoxInfoComponent } from './components/box-info/box-info.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent } from './components/stepper/step/step.component';
import { MultiselectSearchComponent } from './components/multiselect-search/multiselect-search.component';
import { ListTypeInjectDirective } from './directives/list-type-inject.directive';
import { FormatEnumPipe } from './pipes/format-enum.pipe';

@NgModule({
  declarations: [
    SideNavComponent,
    ConfirmationModalComponent,
    LoadingComponent,
    HeaderComponent,
    DropdownComponent,
    FooterComponent,
    SnackbarComponent,
    InputContainerComponent,
    InputDirective,
    ContentContainerComponent,
    ButtonComponent,
    FileUploadComponent,
    InputSearchComponent,
    SelectSearchContainerComponent,
    ListComponent,
    TopListComponent,
    PaginationListComponent,
    PanelBoxComponent,
    MediaDisplayerModalComponent,
    BirthdateValidatorDirective,
    CircleLoadingComponent,
    MultiselectComponent,
    OutlinedBoxComponent,
    ListContainerComponent,
    NotFoundMessageListComponent,
    TotalRecordsListComponent,
    DatetimeFormatPipe,
    MessageComponent,
    ModalComponent,
    DragDropComponent,
    SwitchComponent,
    MarqueeComponent,
    OffcanvasComponent,
    BoxInfoComponent,
    ListItemComponent,
    ListTitleComponent,
    ListInfoComponent,
    ListDividerComponent,
    MenuBarComponent,
    MenuBarItemComponent,
    OffcanvasComponent,
    StepperComponent,
    StepComponent,
    MultiselectSearchComponent,
    ListTypeInjectDirective,
    FormatEnumPipe,
  ],
    imports: [
        CommonModule,
        RouterLink,
        HttpClientModule,
        RouterLinkActive,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    exports: [
        SideNavComponent,
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        SnackbarComponent,
        InputContainerComponent,
        InputDirective,
        ContentContainerComponent,
        ButtonComponent,
        FileUploadComponent,
        InputSearchComponent,
        SelectSearchContainerComponent,
        ListComponent,
        TopListComponent,
        PaginationListComponent,
        PanelBoxComponent,
        MediaDisplayerModalComponent,
        BirthdateValidatorDirective,
        CircleLoadingComponent,
        MultiselectComponent,
        OutlinedBoxComponent,
        ListContainerComponent,
        NotFoundMessageListComponent,
        ConfirmationModalComponent,
        TotalRecordsListComponent,
        DatetimeFormatPipe,
        MessageComponent,
        ModalComponent,
        DragDropComponent,
        SwitchComponent,
        BoxInfoComponent,
        SwitchComponent,
        ListItemComponent,
        ListTitleComponent,
        ListInfoComponent,
        ListDividerComponent,
        MenuBarComponent,
        MenuBarItemComponent,
        OffcanvasComponent,
        StepperComponent,
        StepComponent,
        MultiselectSearchComponent,
        ListTypeInjectDirective,
        FormatEnumPipe,
    ],
})
export class SharedModule {
}
