import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ICompetitionBlockDto } from "@models/competition-block-dto";
import { CompleteBlockCompetitionDto } from "@models/complete-block-competition-dto";
import { LinkServiceUserFilter } from "@models/enums/link-service-user-filter";
import { IEventDto } from "@models/event-dto";
import { EventFilter } from "@models/events/event-filter";
import { IBodyExceptionDto } from "@models/exceptions/body-error-dto";
import { ResponseDto, ResponseWithErrorDto, IResponseDto } from "@models/exceptions/response-dto";
import { EventPostForm } from "@models/form/event-post-form";
import { EventPutForm } from "@models/form/put/event-put-form";
import { IPage } from "@models/pagination/page";
import { ISportDto } from "@models/sport-dto";
import { IUserDto } from "@models/user-dto";
import { downloadFile, catchDownloadFileError, extractResponse } from "@shared/rxjs/custom-rxjs-operators";
import { Observable, map, catchError, EMPTY, tap } from "rxjs";
import { MessageErrorResolver } from "../../utilities/message-error-resolver";
import { LoadingService } from "./loading.service";
import { IUserFieldsValidatorDto } from "@models/portal/validator/dto/user-fields-validator-dto";
import { IEventValidationsDto } from "@models/events/dto/event-validations-dto";


@Injectable({
  providedIn: 'root'
})
export class EventService {
  private url: string = environment.apiUrl + "/v1/eventos";


  constructor(private httpClient: HttpClient,
              private loadingService: LoadingService,
              private messageErrorResolver: MessageErrorResolver) {
  }

  public getAllEvents(page?: number, size?: number): Observable<IPage<IEventDto>> {
    let params = new HttpParams();
    if (page) params = params.set("page", page);
    if (size) params = params.set("size", size);
    return this.httpClient.get<ResponseDto<IPage<IEventDto>>>(this.url, {params})
      .pipe(
        map(response => response.response),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        })
      );
  }

  findValidationsFromEvent(eveNrId: number): Observable<IEventValidationsDto> {
    return this.httpClient.get<ResponseDto<IEventValidationsDto>>(`${this.url}/${eveNrId}/validadores`).pipe(extractResponse());
  }

  public getAllEventsOwner(filter?: EventFilter, page?: number, size?: number): Observable<IPage<IEventDto>> {
    let params = new HttpParams();
    if (page) params = params.set("page", page);
    if (size) params = params.set("size", size);
    if (filter?.eveTxNome) params = params.set("eveTxNome", filter.eveTxNome);
    if (filter?.eveTxDescricao) params = params.set("eveTxDescricao", filter.eveTxDescricao);
    if (filter?.fedNrId) params = params.set("fedNrId", filter.fedNrId);
    if (filter?.estNrId) params = params.set("estNrId", filter.estNrId);
    if (filter?.ano) params = params.set("ano", filter.ano);

    return this.httpClient.get<ResponseDto<IPage<IEventDto>>>(this.url.concat("/proprietario"), {params})
      .pipe(
        map(response => response.response),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        })
      );
  }

  public getAllEventsBasicData(page?: number, size?: number): Observable<IPage<IEventDto>> {
    let params = new HttpParams();
    if (page) params = params.set("page", page);
    if (size) params = params.set("size", size);
    return this.httpClient.get<ResponseDto<IPage<IEventDto>>>(this.url.concat("/dados-basicos"), { params })
      .pipe(
        map(response => response.response),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        })
      );
  }

  public getAllCompetitionBlocksByEvent$(eveNrId: number, page?: number, size?: number): Observable<IPage<ICompetitionBlockDto>> {
    let params = new HttpParams();
    if (page) params = params.set("page", page);
    if (size) params = params.set("size", size);
    return this.httpClient.get<ResponseDto<IPage<ICompetitionBlockDto>>>(environment.apiUrl + `/v2/eventos/${eveNrId}/blocos-competicoes`, {params})
      .pipe(
        map(response => response.response),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        })
      );
  }

  public postEvent(form: EventPostForm): Observable<IEventDto> {
    return this.httpClient.post<ResponseDto<IEventDto>>(this.url, form)
      .pipe(
        map(response => response.response),
        catchError(err => {
          this.loadingService.deactivate();
          throw err;
        }),
        tap(() => this.loadingService.deactivate())
      );
  }

  public getEventById(eveNrId: number): Observable<IEventDto> {
    return this.httpClient.get<ResponseDto<IEventDto>>(this.url + `/${eveNrId}/completo`)
      .pipe(
        map(response => response.response),
        catchError(err => {
          this.loadingService.deactivate();
          throw err;
        }),
      );
  }

  public verifyIfAthleteAlreadyHaveAttachments(eveNrId: number, usuNrId: number): Observable<boolean> {
    return this.httpClient.get<ResponseDto<boolean>>(this.url + `/${eveNrId}/inscricoes/usuario/${usuNrId}`)
      .pipe(
        map(response => response.response),
        catchError(err => {
          this.loadingService.deactivate();
          throw err;
        }),
      );
  }

  public getAllCompleteCompetitionBlocksByEvent(eveNrId: number, page?: number, size?: number): Observable<IPage<CompleteBlockCompetitionDto>> {
    let params = new HttpParams();
    if (page) params = params.set("page", page);
    if (size) params = params.set("size", size);
    return this.httpClient.get<ResponseDto<IPage<CompleteBlockCompetitionDto>>>(this.url + `/${eveNrId}/blocos-competicoes/complete`, {params})
      .pipe(
        map(response => response.response),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        })
      );
  }

  public putEvent(formToPut: EventPutForm): Observable<IPage<EventPutForm>> {
    return this.httpClient.put<ResponseDto<IPage<EventPutForm>>>(`${this.url}`, formToPut)
      .pipe(
        map(response => response.response),
        catchError(err => {
          this.loadingService.deactivate();
          throw err;
        }),
      );
  }

  public deleteEvent(eveNrId: number) {
    return this.httpClient.delete(this.url + `/${eveNrId}`)
      .pipe(
        catchError(err => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(err);
          throw err;
        }),
      );
  }

  getAllAvailableEvents(page?: number, size?: number) {
    let params = new HttpParams();
    if (page) params = params.set("page", page);
    if (size) params = params.set("size", size);
    return this.httpClient.get<ResponseDto<IPage<IEventDto>>>(environment.apiUrl.concat("/v1/inscricoes/eventos-disponiveis"), {params})
      .pipe(
        map(response => response.response),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          this.messageErrorResolver.resolveMessage(error);
          return EMPTY;
        })
      );
  }


  findAllSportsByEventId(eveNrId: number, page?: number, size?: number): Observable<IPage<ISportDto>> {
    let params = new HttpParams();
    if (page) params = params.set("page", page);
    if (size) params = params.set("size", size);
    return this.httpClient.get<ResponseDto<IPage<ISportDto>>>(this.url.concat(`/${eveNrId}`).concat("/esportes"), {params})
      .pipe(
        map(response => response.response),
        catchError((error: ResponseWithErrorDto) => {
          this.loadingService.deactivate();
          return EMPTY;
        })
      );
  }

  public getEventPreview(eveNrId: number, attachmentType: "CREDENCIAL" | "CERTIFICADO"): Observable<string> {
    const params: HttpParams = new HttpParams().set("tipoAnexo", attachmentType);
    return this.httpClient.get(this.url.concat(`/${eveNrId}/modelos-anexos/preview`), {
      responseType: 'arraybuffer',
      observe: 'response',
      params: params
    }).pipe(
      catchError(err => {
        this.loadingService.deactivate();
        return EMPTY;
      }),
      map(res => {
        this.loadingService.deactivate();
        const byteArray = new Uint8Array(res.body!);
        let binary = '';
        for (let i = 0; i < byteArray.length; i++) {
          binary += String.fromCharCode(byteArray[i]);
        }
        const base64 = btoa(binary);
        const contentType: string = res.headers.get("content-type")!;
        // data:[MIME type];base64,[dados codificados em Base64] ---  Data URI (Uniform Resource Identifier)
        return "data:".concat(contentType).concat(";base64,").concat(base64);
      })
    );
  }

  downloadEventAttachmentsByEnrollment(eveNrId: number, incNrId: number, attachmentType: "CREDENCIAL" | "CERTIFICADO") {
    let params = new HttpParams();
    if (attachmentType) params = params.set("tipoAnexoEnum", attachmentType);
    return this.httpClient.get(this.url.concat(`/${eveNrId}/inscricoes/${incNrId}/modelos-anexos/gerar`), {
      params,
      responseType: 'arraybuffer',
      observe: 'response',
    }).pipe(
      downloadFile(),
      catchDownloadFileError((res: IResponseDto<IBodyExceptionDto>) => {
        this.loadingService.deactivate();
        this.messageErrorResolver.resolveMessage(res);
      }),
    );
  }

  downloadEventAttachmentsByUser(eveNrId: number, usuNrId: number, attachmentType: "CREDENCIAL" | "CERTIFICADO") {
    let params = new HttpParams();
    if (attachmentType) params = params.set("tipoAnexoEnum", attachmentType);
    return this.httpClient.get(this.url.concat(`/${eveNrId}/usuarios/${usuNrId}/modelos-anexos/gerar`), {
      params,
      responseType: 'arraybuffer',
      observe: 'response',
    }).pipe(
      downloadFile(),
      catchDownloadFileError((res: IResponseDto<IBodyExceptionDto>) => {
        this.loadingService.deactivate();
        this.messageErrorResolver.resolveMessage(res);
      }),
    );
  }

  downloadEventAttachmentsByFederation(eveNrId: number, fedNrId: number, attachmentType: "CREDENCIAL" | "CERTIFICADO") {
    let params = new HttpParams();
    if (attachmentType) params = params.set("tipoAnexoEnum", attachmentType);
    if (fedNrId) params = params.set("fedNrId", fedNrId);
    return this.httpClient.get(this.url.concat(`/${eveNrId}/modelos-anexos/gerar`), {
      params,
      responseType: 'arraybuffer',
      observe: 'response',
    }).pipe(
      downloadFile(),
      catchDownloadFileError((res: IResponseDto<IBodyExceptionDto>) => {
        this.loadingService.deactivate();
        this.messageErrorResolver.resolveMessage(res);
      }),
    );
  }
}
