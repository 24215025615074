import { Directive, Input } from '@angular/core';

interface ListTemplateContext<T> {
  $implicit: T;
  list: T;
}

@Directive({
  selector: 'ng-template[listTypeInject]',
})
export class ListTypeInjectDirective<T> {

  @Input('listTypeInject') listRef?: T[];

  constructor() { }

  static ngTemplateContextGuard<TContext>(dir: ListTypeInjectDirective<TContext>, ctx: unknown): ctx is ListTemplateContext<TContext> {
    return true;
  }
}
