import { SnackbarService } from './../../services/snackbar.service';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, booleanAttribute } from '@angular/core';
import { ArrayUtils } from 'src/app/core/utilities/array-utils';
import { Utils } from 'src/app/core/utilities/utils';

@Component({
  selector: 'sigecom-drag-drop',
  template: `
    <div class="w-full p-6 flex border border-sigecom-outlined-gray rounded-md select-none pb-20 relative">
      <!-- AVAILABLE SPACE -->
      <div class="flex-1">
        <h4 class="font-bold text-xl mb-4 text-sigecom-dark-gray">Validações inativas</h4>
        <section class="h-96 border border-[#B9C0C7] text-slate-800 rounded-md p-2 overflow-y-auto">
          <ng-container *ngIf="availableItems.length > 0; else emptyAvailables">
            <ng-container *ngFor="let item of availableItems; trackBy: tracker">

              <div class="flex justify-between gap-2 items-center p-2 bg-white rounded-sm border-b border-b-slate-700 text-sm mb-1 cursor-pointer"
                [ngClass]="{'bg-sigecom-item-selected': hasInSelectedAvailables(item)}"
                (click)="selectAvailableItem(item)"
              >
                <p>{{ item[itemDisplayProperty!] }}</p>
                <span *ngIf="hasInSelectedAvailables(item)">✔</span>
              </div>

            </ng-container>
          </ng-container>
          <ng-template #emptyAvailables>
            <p class="text-sm p-2">{{ spaceMessage || 'Nenhum item disponível' }}</p>
          </ng-template>
        </section>
      </div>

      <div class="flex flex-col self-center gap-4 mx-6 text-slate-800">
        <button *ngIf="justAdd && !justRemove" (click)="moveToCurrents()" class="border border-sigecom-gray rounded-md w-9 h-9">
          <fa-icon icon="angle-right"></fa-icon>
        </button>
        <button *ngIf="justRemove && !justAdd" (click)="moveToAvailables()" class="border border-sigecom-gray rounded-md w-9 h-9">
          <fa-icon icon="angle-left"></fa-icon>
        </button>
      </div>

      <!-- CURRENT SPACE -->
      <div class="flex-1">
        <h4 class="font-bold text-xl mb-4 text-sigecom-dark-gray">Validações ativas</h4>
        <section class="h-96 border border-[#B9C0C7] text-slate-800 rounded-md p-2 overflow-y-auto">
          <ng-container *ngIf="currentItems.length > 0; else emptyCurrents">
            <ng-container *ngFor="let item of currentItems; trackBy: tracker">

              <div class="flex items-center justify-between border-b border-b-slate-700"
                [ngClass]="{'border-b border-b-slate-700 mb-1': actionModal}">

                <div class="flex-1 flex justify-between items-center p-2 bg-white rounded-sm text-sm cursor-pointer"
                  [ngClass]="{
                    'bg-sigecom-item-selected': hasInSelectedCurrents(item),
                    'border-b border-b-slate-700 mb-1': !actionModal
                  }"
                  (click)="selectCurrentItem(item)"
                >
                  <p>{{ item[itemDisplayProperty!] }}</p>
                  <span *ngIf="hasInSelectedCurrents(item)">✔</span>
                </div>

                <ng-container *ngIf="actionModal">
                  <button (click)="selectedToActionEmit(item)" class="flex items-center justify-center p-1" >
                    <fa-icon icon="gear" class="text-sigecom-dark-gray"/>
                  </button>
                  <button (click)="removeChange.emit(item)" class="flex items-center justify-center p-1" >
                    <fa-icon icon="trash" class="text-sigecom-danger"/>
                  </button>
                </ng-container>
              </div>

            </ng-container>
          </ng-container>
          <ng-template #emptyCurrents>
            <p class="text-sm p-2">{{ spaceMessage || 'Nenhum item disponível' }}</p>
          </ng-template>
        </section>
      </div>

      <button *ngIf="!nonRequiredApply" class="flex items-center gap-2 border border-[#B9C0C7] bg-white text-slate-800 px-4 py-2 rounded-md absolute
        shadow-md right-6 bottom-5 disabled:opacity-60" (click)="apply()" [disabled]="disabled()">
        <span class="font-semibold">Aplicar</span>
        <fa-icon [icon]="'check'" *ngIf="!loading"></fa-icon>
        <sigecom-circle-loading *ngIf="loading" color="rgb(30 41 59)" [sizeInRem]="1"/>
      </button>
    </div>
  `,
  styleUrls: ['./drag-drop.component.scss']
})
export class DragDropComponent<T> implements OnChanges  {

  private previousCurrentItems: T[] = [];

  @Input({ transform: booleanAttribute }) justAdd: boolean = false;
  @Input({ transform: booleanAttribute }) justRemove: boolean = false;
  @Input({ transform: booleanAttribute }) nonRequiredApply: boolean = false;
  @Input() actionModal: boolean = false;
  @Output() selectedItemToAction: EventEmitter<T> = new EventEmitter<T>();

  @Input() loading: boolean = false;
  @Input() availableItems: T[] = [];
  @Input() currentItems: T[] = [];
  @Input() itemDisplayProperty?: keyof T;
  @Input() spaceMessage?: string;

  @Output() itemsChange = new EventEmitter<IDragDropOutputScheme<T>>();
  @Output() removeChange = new EventEmitter<T>();

  selectedAvailablesItems: T[] = [];
  selectedCurrentItems: T[] = [];

  constructor(
    private snackbarService: SnackbarService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['availableItems']?.currentValue) {
      this.availableItems = changes['availableItems'].currentValue;
    }
    if (changes['currentItems']?.currentValue) {
      this.currentItems = changes['currentItems'].currentValue;
      this.previousCurrentItems = [...this.currentItems];
    }
  }

  apply() {
    if (this.nonRequiredApply) return;

    const itemsAdded = [] as T[];
    const itemsRemoved = [] as T[];

    this.currentItems.forEach(item => {
      if (!this.previousCurrentItems.includes(item)) {
        itemsAdded.push(item);
      }
    });

    this.previousCurrentItems.forEach(item => {
      if (!this.currentItems.includes(item)) {
        itemsRemoved.push(item);
      }
    });

    this.itemsChange.emit({ itemsAdded, itemsRemoved } as IDragDropOutputScheme<T>);
    this.selectedAvailablesItems = [];
    this.selectedCurrentItems = [];
  }

  moveToCurrents(): void {
    this.selectedAvailablesItems.forEach((item) => {
      Utils.removeElementFromArray(this.availableItems, item);
      if (!this.currentItems.includes(item)) {
        this.currentItems.push(item);
        this.nonRequiredApplyEmitFn(item, 'add');
      }
    });
    this.resetSelectedAvailablesItems();
  }

  moveToAvailables(): void {
    this.selectedCurrentItems.forEach((item) => {
      Utils.removeElementFromArray(this.currentItems, item);
      if (!this.availableItems.includes(item)) {
        this.availableItems.push(item);
        this.nonRequiredApplyEmitFn(item, 'remove');
      }
    });
    this.resetSelectedCurrentItems();
  }

  nonRequiredApplyEmitFn(item: T, type: 'add' | 'remove') {
    if (this.nonRequiredApply) {
      const changes: IDragDropOutputScheme<T> = {
        itemsAdded: type === 'add' ? [item] : [],
        itemsRemoved: type === 'remove' ? [item] : []
      };
      this.itemsChange.emit(changes);
    }
  }

  selectAvailableItem(item: T): void {
    if (this.selectedAvailablesItems.includes(item)) {
      Utils.removeElementFromArray(this.selectedAvailablesItems, item);
    } else {
      this.selectedAvailablesItems.push(item);
    }
  }

  selectCurrentItem(item: T): void {
    if (this.selectedCurrentItems.includes(item)) {
      Utils.removeElementFromArray(this.selectedCurrentItems, item);
    } else {
      this.selectedCurrentItems.push(item);
    }
  }

  hasInSelectedAvailables(item: T): boolean {
    return this.selectedAvailablesItems.includes(item);
  }

  hasInSelectedCurrents(item: T): boolean {
    return this.selectedCurrentItems.includes(item);
  }

  resetSelectedAvailablesItems(): void {
    this.selectedAvailablesItems = [];
  }

  resetSelectedCurrentItems(): void {
    this.selectedCurrentItems = [];
  }

  disabled() {
    const emptyLists = (this.availableItems.length === 0 && this.currentItems.length === 0);
    const noChanges = ArrayUtils.arraysIsEquals(this.previousCurrentItems, this.currentItems);
    return emptyLists || noChanges;
  }

  // TODO: permitir editar os demais items e recusar a edição apenas para os items que foram adicionados
  selectedToActionEmit(item: T) {
    if (this.nonRequiredApply) {
      this.selectedItemToAction.emit(item);
      return;
    }
    const noChanges = ArrayUtils.arraysIsEquals(this.previousCurrentItems, this.currentItems);
    noChanges ? this.selectedItemToAction.emit(item) : this.snackbarService.showWarn("Aplique as alterações antes de editar um item");
  }

  protected tracker = (index: number) => index;
}

export interface IDragDropOutputScheme<T> {
  itemsAdded: T[];
  itemsRemoved: T[];
}

