import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { IconName } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sigecom-menu-bar-item',
  template: `
    <button (click)="clickAction.emit()" class="w-full flex items-center gap-3">
      <fa-icon *ngIf="icon" [icon]="icon!" class="text-{{color}}" />
      <span *ngIf="label" class="font-medium text-mms-text-primary text-lg">{{ label }}</span>
    </button>
  `,
  styles: [
  ]
})
export class MenuBarItemComponent {
  @Input() label?: string;
  @Input() icon?: IconName;
  @Input() color: string = 'sigecom-info';
  @Output() clickAction: EventEmitter<void> = new EventEmitter<void>();

  protected showTooltip = signal(false);
}
