import { IconName } from "@fortawesome/free-solid-svg-icons";

export interface IMenuItem {
	opened: boolean;
	title: string;
	route: string[];
	icon: string;
	subitens: MenuItem[];
	switch?: () => void;
}

export class MenuItem implements IMenuItem {
	public opened: boolean;
	public title: string;
	public route: string[];
	public icon: IconName;
	public subitens: MenuItem[];

	constructor(
		title: string,
		route: string[],
		icon: IconName,
		subitens: MenuItem[]
	) {
		this.opened = false;
		this.title = title;
		this.route = route;
		this.icon = icon;
		this.subitens = subitens;
	}

	addSubMenuItems(subItems: MenuItem[]) {
		this.subitens.push(...subItems)
	}

	isOpen() {
		return this.opened;
	}

	close() {
		this.opened = false;
	}

	open() {
		this.opened = true;
	}

	switch() {
		this.opened = !this.opened;
	}
}
