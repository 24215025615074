import { Component, Input } from '@angular/core';

@Component({
  selector: 'sigecom-circle-loading',
  template: `
    <div [style.width.rem]="sizeInRem" [style.height.rem]="sizeInRem" style="background-color: transparent;">
      <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" [attr.stroke]="color" stroke-width="5"></circle>
      </svg>
    </div>
  `,
  styleUrls: ['./circle-loading.component.scss']
})
export class CircleLoadingComponent {
  @Input() sizeInRem: number = 2;
  @Input() color: string = "#13388f";
}
