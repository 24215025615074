import { Component, Input } from '@angular/core';

@Component({
  selector: 'sigecom-total-records-list',
  templateUrl: './total-records-list.component.html',
  styleUrls: ['./total-records-list.component.scss']
})
export class TotalRecordsListComponent {
  @Input() totalRecords?: number;
  @Input() label?: string;
}
